import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";

export default function EditForm({ fid, closeEvent }) {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [slug, setSlug] = useState("");
  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0);
  const baseURL = process.env.REACT_APP_API_URL;
  const updateCategories = useAppStore((state) => state.updateCategories);
  const token = useAppStore((state) => state.token);

  useEffect(() => {
    console.log("FID: " + fid.id);
    setName(fid.name);
    setDescription(fid.description);
    setImage(fid.image);
    setSlug(fid.slug);
  }, []);

  const createUser = async (url) => {
    let posturl = baseURL + "category/" + fid.id;

    await axios
      .put(
        posturl,
        {
          name: name,
          description: description,
          slug: slug,
          image: image,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        console.log(res.data);
        getUsers();
        closeEvent();
        Swal.fire("Submitted!", "Your file has been submitted.", "success");
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
  };

  const getUsers = async () => {
    let posturl = baseURL + "category";
    await axios
      .get(posturl,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      .then((res) => {
        updateCategories(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("kcheckpost" + err); //return 429
      });
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSlugChange = (event) => {
    setSlug(event.target.value);
  };

  const handlePicChange = (event) => {
    console.log(event.target.files);
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage(e.target.result);
    };
  };
  return (
    <>
      <Box sx={{ m: 2 }} />
      <Typography variant="h5" align="center">
        Edit Category
      </Typography>
      <IconButton
        style={{ position: "absolute", top: "0", right: "0" }}
        onClick={closeEvent}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            error={false}
            id="name"
            name="name"
            value={name}
            onChange={handleNameChange}
            label="Name"
            size="small"
            sx={{ marginTop: "30px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            id="description"
            name="description"
            value={description}
            onChange={handleDescriptionChange}
            label="Description"
            size="small"
            sx={{ marginTop: "0px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={false}
            value={slug}
            onChange={handleSlugChange}
            label="Slug"
            size="small"
            sx={{ marginTop: "0px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <input type="file" onChange={handlePicChange} accept="/image/*" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            <Button variant="contained" onClick={createUser}>
              Submit
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
