import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import Post from "./pages/Post";
import AddPost from "./post/AddPost";
import EditPost from "./post/EditPost";
import Category from "./pages/Category";
import User from "./pages/User";
import Login from "./Login";
import { useAppStore } from "./appStore";
import { ToastContainer } from "react-toastify";

export default function App() {
  const utype = useAppStore((state) => state.utype);

  return (
    <>
      <ToastContainer />
      <HashRouter>
        <Routes>
          <Route path="/" exact element={<Login />}></Route>
          {utype === "Admin" && (
            <>
              <Route path="/post" exact element={<Post />}></Route>
              <Route path="/category" exact element={<Category />}></Route>
              <Route path="/user" exact element={<User />}></Route>
              <Route path="/add-post" exact element={<AddPost />}></Route>
              <Route path="/edit-post" exact element={<EditPost />}></Route>
            </>
          )}
          {utype === "Editor" && (
            <>
              <Route path="/post" exact element={<Post />}></Route>
              <Route path="/add-post" exact element={<AddPost />}></Route>
              <Route path="/edit-post" exact element={<EditPost />}></Route>
            </>
          )}
        </Routes>
      </HashRouter>
    </>
  );
}
