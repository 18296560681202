import Sidenav from "../components/Sidenav";
import Box from "@mui/material/Box";
import Navbar from "../components/Navbar";
import PostList from "../post/PostList";
import EditorPostList from "../post/EditorPostList";
import "../Dash.css";
import { useAppStore } from "../appStore";

export default function Post() {
  const utype = useAppStore((state) => state.utype);

  return (
    <>
      <div className="bgcolor">
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {utype === "Admin" && <PostList />}
            {utype === "Editor" && <EditorPostList />}
          </Box>
        </Box>
      </div>
    </>
  );
}
