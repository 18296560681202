import create from "zustand";
import { persist } from "zustand/middleware";

let appStore = (set) => ({
  dopen: true,
  rows: [],
  username: '',
  password: '',
  categories: [],
  posts: [],
  users: [],
  token: '',
  utype: '',
  uid: '',
  updateUid: (uid) => set((state) => ({ uid: uid })),
  updateUtype: (utype) => set((state) => ({ utype: utype })),
  updateToken: (token) => set((state) => ({ token: token })),
  updateUsers: (users) => set((state) => ({ users: users })),
  updatePosts: (posts) => set((state) => ({ posts: posts })),
  updateCategories: (categories) => set((state) => ({ categories: categories })),
  updateUsername: (username) => set((state) => ({ username: username })),
  updatePassword: (password) => set((state) => ({ password: password })),
  setRows: (rows) => set((state) => ({ rows: rows })),
  updateDopen: (dopen) => set((state) => ({ dopen: dopen })),
});

appStore = persist(appStore, { name: "cdot_store_api" });
export const useAppStore = create(appStore);
