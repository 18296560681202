import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { Typography, Box, Card } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import JoditEditor from "jodit-react";
import Sidenav from "../components/Sidenav";
import Navbar from "../components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddPost() {
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [status, setStatus] = useState("Published");
  const [type, setType] = useState("Post");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const baseURL = process.env.REACT_APP_API_URL;
  const editor = useRef(null);
  const img = useRef("");
  const navigate = useNavigate();
  const categories = useAppStore((state) => state.categories);
  const token = useAppStore((state) => state.token);
  const uid = useAppStore((state) => state.uid);
  const config = useMemo(
    () => ({
      readonly: false,
    }),
    []
  );

  const createUser = async (url) => {
    const tid = toast.loading("Please wait...");
    let posturl = baseURL + "posts";

    console.log("Daya: ", img.current);

    await axios
      .post(
        posturl,
        {
          title: title,
          description: description,
          keywords: keywords,
          category: category,
          status: status,
          type: type,
          image: image,
          user_id: uid,
          content: content,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        toast.update(tid, {
          render: "Created 😊",
          type: "success",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          isLoading: false,
        });
        navigate("/post");
      })
      .catch(function (error) {
        toast.update(tid, {
          render: "Something went wrong",
          type: "error",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          isLoading: false,
        });
        console.log("kcheckpost" + error); //return 429
      });
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleKeywordChange = (event) => {
    setKeywords(event.target.value);
  };

  const handlePicChange = (event) => {
    console.log(event.target.files);
    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = (e) => {
      setImage(e.target.result);
    };
  };

  const onBlur = useCallback(
    (newContent) => {
      setContent(newContent);
    },
    [setContent]
  );

  return (
    <>
      <div className="bgcolor">
        <Navbar />
        <Box height={70} />
        <Box sx={{ display: "flex" }}>
          <Sidenav />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Card sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
              <Typography variant="h5" align="center">
                Add Post
              </Typography>
              <Box height={10} />
              <IconButton
                style={{ position: "absolute", top: "-10", left: "20" }}
                onClick={() => {
                  navigate("/post");
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <Box height={20} />
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={false}
                    id="title"
                    name="title"
                    value={title}
                    onChange={handleTitleChange}
                    label="Title*"
                    size="small"
                    sx={{ marginTop: "30px", minWidth: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={false}
                    id="description"
                    name="description"
                    value={description}
                    onChange={handleDescriptionChange}
                    label="Description"
                    size="small"
                    sx={{ marginTop: "0px", minWidth: "100%" }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={false}
                    id="keywords"
                    name="keywords"
                    value={keywords}
                    onChange={handleKeywordChange}
                    label="Keywords"
                    size="small"
                    sx={{ marginTop: "0px", minWidth: "100%" }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: "92%" }}
                    size="small"
                  >
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={category}
                      onChange={handleCategoryChange}
                      label="Category"
                    >
                      {categories.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="branch-label">Status*</InputLabel>
                    <Select
                      labelId="branch-label"
                      size="small"
                      value={status}
                      label="Status*"
                      onChange={handleStatusChange}
                    >
                      <MenuItem value="Published">Published</MenuItem>
                      <MenuItem value="Drafted">Drafted</MenuItem>
                      <MenuItem value="Trashed">Trashed</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="branch-label">Type*</InputLabel>
                    <Select
                      labelId="branch-label"
                      size="small"
                      value={type}
                      label="Type*"
                      onChange={handleTypeChange}
                    >
                      <MenuItem value="Post">Post</MenuItem>
                      <MenuItem value="Page">Page</MenuItem>
                      <MenuItem value="Blog">Blog</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <JoditEditor
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={onBlur}
                  />
                </Grid>

                <Grid item xs={12}>
                  <input
                    type="file"
                    onChange={handlePicChange}
                    accept="/image/*"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h5" align="center">
                    <Button variant="contained" onClick={createUser}>
                      Submit
                    </Button>
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </Box>
      </div>
    </>
  );
}
