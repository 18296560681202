import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Skeleton from "@mui/material/Skeleton";
import { useAppStore } from "../appStore";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

export default function PostList() {
  const baseURL = process.env.REACT_APP_API_URL;
  const postimgURL = process.env.REACT_APP_POST_URL;
  const [page, setPage] = useState(0);
  const [post, setPost] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [formid, setFormid] = useState("");
  const posts = useAppStore((state) => state.posts);
  const updatePosts = useAppStore((state) => state.updatePosts);
  const updateCategories = useAppStore((state) => state.updateCategories);
  const categories = useAppStore((state) => state.categories);
  const token = useAppStore((state) => state.token);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    getUsers();
    getCategories();
    console.log("posts ", categories);
  }, []);

  const getCategories = async () => {
    let posturl = baseURL + "category";
    await axios
      .get(posturl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        updateCategories(res.data);
      })
      .catch((err) => {
        console.log("kcheckpost" + err); //return 429
      });
  };

  const getUsers = async () => {
    let posturl = baseURL + "posts";
    await axios
      .get(posturl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        updatePosts(res.data);
        setPost(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("kcheckpost" + err); //return 429
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteUser = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        deleteApi(id);
      }
    });
  };

  const deleteApi = async (id) => {
    let posturl = baseURL + "posts/" + id;
    await axios
      .delete(posturl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(function (res) {
        console.log(res.data);
        getUsers();
        Swal.fire("Submitted!", "Your file has been submitted.", "success");
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
  };

  const filterData = (v) => {
    if (v) {
      // updatePosts([v]);
      setPost([v]);
    } else {
      getUsers();
    }
  };

  const editData = (
    id,
    title,
    description,
    keywords,
    image,
    category,
    slug,
    status,
    type,
    content
  ) => {
    const data = {
      id: id,
      title: title,
      description: description,
      keywords: keywords,
      image: image,
      category: category,
      slug: slug,
      status: status,
      type: type,
      content: content,
    };
    setFormid(data);
    navigate("/edit-post", {
      state: {
        id: id,
        title: title,
        description: description,
        keywords: keywords,
        image: image,
        category: category,
        slug: slug,
        status: status,
        type: type,
        content: content,
      },
    });
  };

  const handleStatusChange = (e) => {
    if (e) {
      let val = e.target.value;
      var emplist = posts.filter((data) => data.status == val);
      setPost(emplist);
    }
  };

  const handleTypeChange = (e) => {
    if (e) {
      let val = e.target.value;
      var emplist = posts.filter((data) => data.type == val);
      setPost(emplist);
    }
  };

  return (
    <>
      <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          sx={{ padding: "20px" }}
        >
          Posts
        </Typography>
        <Divider />
        <Box height={10} />
        <Stack direction="row" spacing={2} className="my-2 mb-2">
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={posts}
            sx={{ width: 300 }}
            onChange={(e, v) => filterData(v)}
            getOptionLabel={(posts) => posts.title || ""}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Search Post" />
            )}
          />
          <FormControl
            variant="outlined"
            sx={{ minWidth: "200px" }}
            size="small"
          >
            <InputLabel id="status-label">Staus</InputLabel>
            <Select
              labelId="status-label"
              size="small"
              label="Status*"
              onChange={handleStatusChange}
            >
              <MenuItem value="Published">Published</MenuItem>
              <MenuItem value="Drafted">Drafted</MenuItem>
              <MenuItem value="Trashed">Trashed</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            sx={{ minWidth: "200px" }}
            size="small"
          >
            <InputLabel id="type-label">Type</InputLabel>
            <Select
              labelId="type-label"
              size="small"
              label="Type*"
              onChange={handleTypeChange}
            >
              <MenuItem value="Post">Post</MenuItem>
              <MenuItem value="Page">Page</MenuItem>
              <MenuItem value="Blog">Blog</MenuItem>
            </Select>
          </FormControl>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Button
            variant="contained"
            endIcon={<AddCircleIcon />}
            onClick={() => {
              navigate("/add-post");
            }}
          >
            Add
          </Button>
        </Stack>
        <Box height={10} />
        {posts.length > 0 && (
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ minWidth: "100px" }}>
                    Title
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px" }}>
                    Category
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px" }}>
                    Status
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px" }}>
                    Image
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px" }}>
                    Created At
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: "100px" }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {post
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.code}
                      >
                        <TableCell align="left">{row.title}</TableCell>
                        <TableCell align="left">
                          {categories.map((item, index) => {
                            return <>{row.category === item.id && item.name}</>;
                          })}
                        </TableCell>
                        <TableCell align="left">{String(row.status)}</TableCell>
                        <TableCell align="left">
                          <img
                            src={`${postimgURL}${row.image}`}
                            height="70px"
                            width="70px"
                            style={{ borderRadius: "15px" }}
                            loading="lazy"
                          />
                        </TableCell>
                        <TableCell align="left">
                          {moment(row.created_at).format("MMMM Do YYYY")}
                        </TableCell>
                        <TableCell align="left">
                          <Stack spacing={2} direction="row">
                            <EditIcon
                              style={{
                                fontSize: "20px",
                                color: "blue",
                                cursor: "pointer",
                              }}
                              className="cursor-pointer"
                              onClick={() => {
                                editData(
                                  row.id,
                                  row.title,
                                  row.description,
                                  row.keywords,
                                  row.image,
                                  row.category,
                                  row.slug,
                                  row.status,
                                  row.type,
                                  row.content
                                );
                              }}
                            />
                            <DeleteIcon
                              style={{
                                fontSize: "20px",
                                color: "darkred",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                deleteUser(row.id);
                              }}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={post.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {post.length == 0 && (
        <>
          <Paper sx={{ width: "98%", overflow: "hidden", padding: "12px" }}>
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={30} />
            <Box height={40} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
            <Skeleton variant="rectangular" width={"100%"} height={60} />
            <Box height={20} />
          </Paper>
        </>
      )}
    </>
  );
}
